import React, { useState, useCallback } from 'react';

const styles = {
  container: {
    maxWidth: '1200px',
    margin: '20px auto',
    fontFamily: "'Google Sans', sans-serif",
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  header: {
    backgroundColor: '#f8f9fa',
    padding: '15px 20px',
    borderBottom: '1px solid #e9ecef',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'background-color 0.3s',
  },
  headerHover: {
    backgroundColor: '#e9ecef',
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: 0,
    color: '#343a40',
  },
  content: {
    padding: '20px',
  },
  codeExplanation: {
    marginBottom: '20px',
    borderLeft: '3px solid #007bff',
    paddingLeft: '15px',
  },
  code: {
    fontWeight: 'bold',
    color: '#007bff',
    marginBottom: '5px',
  },
  explanation: {
    color: '#495057',
  },
  summary: {
    marginTop: '20px',
    padding: '15px',
    backgroundColor: '#f1f8ff',
    borderRadius: '4px',
  },
  summaryTitle: {
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#0056b3',
  },
  loading: {
    textAlign: 'center',
    padding: '20px',
    color: '#6c757d',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    width: '20px',
    height: '20px',
    border: '3px solid #f3f3f3',
    borderTop: '3px solid #007bff',
    borderRadius: '50%',
    marginRight: '10px',
    animation: 'spin 1s linear infinite',
  },
};

let endPoint = "https://pairigo-backend.up.railway.app";

if (window.location.href.includes("localhost")) {
  endPoint = "http://127.0.0.1:4242";
}

const CodeExplanation = ({ inputText, codes }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [explanationData, setExplanationData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const fetchExplanation = useCallback(async () => {
    if (isLoading || explanationData) return;

    setIsLoading(true);
    try {
      const response = await fetch(`${endPoint}/api/get_code_explanation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chief_complaint: inputText,
          icd_codes_json: codes.map(code => ({
            Code: code.Code,
            Description: code.Description,
            Score: code.Score,
            Diagnosis: code.Diagnosis,
          })),
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setExplanationData(data);
    } catch (error) {
      console.error('Error fetching explanation:', error);
    } finally {
      setIsLoading(false);
    }
  }, [inputText, codes, isLoading, explanationData]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    if (!isOpen && !explanationData) {
      fetchExplanation();
    }
  };

  return (
    <div style={styles.container}>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
      <div
        style={{ ...styles.header, ...(isHovered ? styles.headerHover : {}) }}
        onClick={toggleOpen}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <h2 style={styles.title}>ICD-10 Code Explanations:</h2>
        <span>{isOpen ? '▲' : '▼'}</span>
      </div>
      {isOpen && (
        <div style={styles.content}>
          {isLoading ? (
            <div style={styles.loading}>
              <div style={styles.spinner}></div>
              Loading explanation...
            </div>
          ) : explanationData ? (
            <>
              {explanationData.icd_10_explanations.map((item, index) => (
                <div key={index} style={styles.codeExplanation}>
                  <div style={styles.code}>Code: {item.code}</div>
                  <div style={styles.explanation}>{item.explanation}</div>
                </div>
              ))}
              <div style={styles.summary}>
                <h3 style={styles.summaryTitle}>Overall Summary:</h3>
                <p>{explanationData.overall_summary}</p>
              </div>
            </>
          ) : (
            <div>No explanation available.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default CodeExplanation;