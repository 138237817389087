import React from 'react';
import { Card } from 'primereact/card';
import './CPTCodes.css';
import * as changeCase from "change-case";


const CPTCodesDisplay = ({ cptData }) => {
    if (!cptData || cptData.length === 0) {
        return <div className="error-container">No valid procedure data found</div>;
    }

    return (
        <div className="container">
            <h2 className="icd-10-title">CPT Codes</h2>
            {cptData.map((entry, idx) => (
                <Card key={idx} className="card" title={<h2 className="title">{changeCase.sentenceCase(entry.Reference)}</h2>}>
                    <ul className="list">
                        {entry.Codes && entry.Codes.length > 0 ? (
                            entry.Codes.map((codeObj, index) => (
                                <li key={index} className="list-item">
                                    <span> <span className="code">{codeObj.code}</span>{codeObj.description}</span>
                                </li>
                            ))
                        ) :

                            (
                                <li className="list-item">No CPT codes available</li>
                            )


                        }

                    </ul>
                </Card>
            ))}
        </div>
    );
};

export default CPTCodesDisplay;
