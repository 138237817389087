import React from 'react';
import './CodeInfoCard.css';

const CodeInfoCard = ({ info }) => {
  const fields = [
    { key: 'inclusion_term', label: 'Inclusion Terms' },
    { key: 'excludes1', label: 'Excludes 1' },
    { key: 'excludes2', label: 'Excludes 2' },
    { key: 'includes', label: 'Includes' },
    { key: 'code_first', label: 'Code First' },
    { key: 'additional_codes', label: 'Additional Codes' },
    { key: 'parent', label: 'Parent' },
    { key: 'section_name', label: 'Section Name' },
    { key: 'section_description', label: 'Section Description' },
  ];

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatValue = (value) => {
    if (typeof value === 'string') {
      return capitalizeFirstLetter(value);
    }
    if (Array.isArray(value)) {
      return value.map(item => capitalizeFirstLetter(item));
    }
    return value;
  };

  return (
    <div className="code-info-card">
      {fields.map(({ key, label }) => {
        if (info[key] && (Array.isArray(info[key]) ? info[key].length > 0 : info[key])) {
          return (
            <div key={key} className="info-field">
              <strong className="field-label">{label}:</strong>
              {Array.isArray(info[key]) ? (
                <ul className="field-list">
                  {formatValue(info[key]).map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              ) : (
                <p className="field-value">
                  {typeof info[key] === 'number' ? info[key].toFixed(2) : formatValue(info[key])}
                </p>
              )}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default CodeInfoCard;