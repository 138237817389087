import React, { useState } from 'react';

const LandingPage = () => {
  const [loading, setLoading] = useState(true);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        backgroundColor: '#0e0e0f',
        overflow: 'hidden', // Prevent overflow within the landing page
      }}
    >
      <iframe
        src="/landingpage.html"
        style={{
          display: 'block', // Prevents default spacing issues with iframes
          width: '100%',
          height: '100%',
          border: 'none',
          margin: 0,
          padding: 0,
          backgroundColor: loading ? '#0e0e0f' : 'transparent',
        }}
        onLoad={handleIframeLoad}
        title="Landing Page"
      />
    </div>
  );
};

export default LandingPage;
