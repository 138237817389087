import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';


const styles = {
  container: {
    maxWidth: '1200px',
    margin: '20px auto',
    fontFamily: "'Google Sans', sans-serif",
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  header: {
    backgroundColor: '#f8f9fa',
    padding: '15px 20px',
    borderBottom: '1px solid #e9ecef',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: 0,
    color: '#343a40',
  },
  content: {
    padding: '20px',
  },
  textareaContainer: {
    marginBottom: '15px',
    position: 'relative',
  },
  textarea: {
    width: '100%',
    minHeight: '120px',
    padding: '10px 20px 10px 10px',
    borderRadius: '4px',
    border: '1px solid #ced4da',
    fontSize: '16px',
    resize: 'vertical',
    boxSizing: 'border-box',
    fontFamily: 'Google Sans, sans-serif',
    color: 'black'
  },
  characterCount: {
    fontSize: '14px',
    color: '#6c757d',
    textAlign: 'right',
    marginTop: '5px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '15px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '14px',
    fontWeight: 'bold',
    cursor: 'pointer',
    borderRadius: '4px',
    transition: 'background-color 0.2s, opacity 0.2s',
    width: '120px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  clearButton: {
    backgroundColor: '#e9ecef',
    border: 'none',
    color: '#495057',
    marginRight: '10px',
  },
  analyzeButton: {
    backgroundColor: '#007bff',
    border: 'none',
    color: 'white',
  },
  loadingSpinner: {
    border: '2px solid #f3f3f3',
    borderTop: '2px solid #007bff',
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    animation: 'spin 1s linear infinite',
  },
  hint: {
    fontSize: '12px',
    color: '#6c757d',
    marginTop: '5px',
    marginBottom: '15px',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  disabledMessage: {
    color: '#721c24',
    backgroundColor: '#f8d7da',
    padding: '10px',
    borderRadius: '4px',
    marginTop: '10px',
    fontSize: '14px',
  },
};

const keyframeStyles = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const InputTextArea = ({ onAnalyze, onClear, loading, disabled }) => {
  const [text, setText] = useState('');
  const [showHint, setShowHint] = useState(false);

  const handleTextChange = (e) => {
    const newText = e.target.value.slice(0, 5000);  // Limit to 5000 characters
    setText(newText);
    setShowHint(newText.length > 0 && newText.length < 60);
  };

  const handleClear = () => {
    setText('');
    setShowHint(false);
    onClear();
  };

  const handleAnalyze = () => {
    if (text.length >= 60) {
      onAnalyze(text);
    } else {
      setShowHint(true);
    }
  };

  const isDisabled = disabled || loading || text.length < 60;

  return (
    <>
      <style>{keyframeStyles}</style>
      <div style={styles.container}>
        <div style={styles.header}>
          <h2 style={styles.title}>Input Patient Data</h2>
        </div>
        <div style={styles.content}>
          <div style={styles.textareaContainer}>
            <InputTextarea
              style={{
                ...styles.textarea,
                ...(disabled ? { backgroundColor: '#f8f9fa', cursor: 'not-allowed' } : {}),
              }}
              value={text}
              onChange={handleTextChange}
              placeholder="Enter patient info, diagnoses, chief complaint, procedures, etc."
              disabled={disabled || loading}
              maxLength={5000}
              rows={5}
              cols={30}
              autoResize
            />
          </div>
          <div style={styles.characterCount}>
            {text.length} out of 5000 characters
          </div>
          <div style={{ ...styles.hint, opacity: showHint ? 1 : 0 }}>
            For better results, consider providing more details (aim for at least 60 characters).
          </div>

          <div style={{ textAlign: 'right' }}>

            <Button style={{ justifyContent: 'center', color: '#495057', backgroundColor: '#e9ecef', width: '120px', height: '40px', marginRight: '5px', borderColor: 'rgb(73, 80, 87)', cursor: isDisabled ? 'not-allowed' : 'pointer' }} onClick={handleClear} disabled={isDisabled || loading}>
              Clear
            </Button>

            <Button style={{ justifyContent: 'center', backgroundColor: '#007bff', width: '120px', height: '40px', marginLeft: '5px', borderColor: 'rgb(19 91 168)', cursor: isDisabled ? 'not-allowed' : 'pointer' }} onClick={handleAnalyze} disabled={isDisabled} >
              {loading ? <div style={styles.loadingSpinner} /> : 'Analyze'}
            </Button>

          </div>


        </div>
      </div>
    </>
  );
};

export default InputTextArea;