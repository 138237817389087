import CPTCodesDisplay from './CPTCodes'
import React, { useState, useEffect } from 'react';
import InputTextArea from "./InputTextArea";
import DiagnosisCard from "./DiagnosisCard";
import CodeExplanation from "./CodeExplanation";
import GenerateCPTCodes from "./GenerateCPTCodes";
import GenerateHCPCSCodes from "./GenerateHCPCSCodes";
import UserIcon from "./UserIcon";
import { TabView, TabPanel } from 'primereact/tabview';
import './Dashboard.css'
import CPTCodeSkeleton from './CPTCodeSkeleton'; // Import the skeleton component



const styles = {
  container: {
    backgroundColor: '#f0f2f5',
    minHeight: '100vh',
    padding: '20px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '40px',
  },
  subscriptionBanner: {
    backgroundColor: '#f8d7da',
    color: '#721c24',
    padding: '10px',
    textAlign: 'center',
    marginBottom: '20px',
  },
  subscribeButton: {
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    cursor: 'pointer',
    marginLeft: '10px',
    fontSize: '14px',
    fontWeight: 'bold',
    borderRadius: '4px',
    transition: 'background-color 0.2s',
  },
  inputContainer: {
    paddingBottom: '5px',
  },
  errorCard: {
    backgroundColor: '#f8d7da',
    color: '#dc3545',
    padding: '10px',
    borderRadius: '4px',
    marginBottom: '20px',
  },
  section: {
    marginBottom: '30px',
  },
  separator: {
    border: 'none',
    borderTop: '1px solid #e0e0e0',
    margin: '30px 0',
    width: '100%',
  },
};

let endPoint = "https://pairigo-backend.up.railway.app";

if (window.location.href.includes("localhost")) {
  endPoint = "http://127.0.0.1:4242";
}

const Dashboard = ({ user, subscriptionStatus }) => {
  const [loading, setLoading] = useState(false);
  const [analysisData, setAnalysisData] = useState(null);
  const [inputText, setInputText] = useState('');
  const [serverError, setServerError] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [cptData, setCPTData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);


  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const response = await fetch(`${endPoint}/api/check_subscription`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email: user.email }),
        });
        if (response.status !== 200) {
          setServerError(true);
        } else {
          setServerError(false);
        }
      } catch (error) {
        console.error('Error checking subscription:', error);
        setServerError(true);
      }
    };

    checkSubscription();
  }, [user.email]);


  const handleTabChange = (e) => {
    setActiveIndex(e.index);

    if (e.index === 0 && inputText && !analysisData) {
      handleAnalyze(inputText)
    }

    if (e.index === 1 && inputText && !cptData) {
      handleCPT(inputText);
    }
  };

  const handleSubscribe = async () => {
    try {
      const response = await fetch(`${endPoint}/api/create-checkout-session`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) throw new Error('Failed to create checkout session');
      const { url } = await response.json();
      window.location.href = url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  const handleAnalyze = async (text) => {
    setInputText(text);
    setAnalysisData(null);
    setCPTData(null)
    setLoading(true);
    setFetchError(false);
    setActiveIndex(0)

    try {
      await new Promise(resolve => setTimeout(resolve, 1500)); // Simulate loading delay
      const response = await fetch(`${endPoint}/api/fetch_icd_codes`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ chief_complaint: text }),
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setAnalysisData(data);
    } catch (error) {
      console.error('Error during analysis:', error);
      setFetchError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCPT = async (text) => {
    setCPTData(null);
    setLoading(true);
    setFetchError(false);

    try {
      await new Promise((resolve) => setTimeout(resolve, 1500)); // Simulate loading delay
      const response = await fetch(`${endPoint}/api/fetch_cpt_codes_and_explanations`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ chief_complaint: text }),
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setCPTData(data); // Ensure data is set here
    } catch (error) {
      console.error('Error during analysis:', error);
      setFetchError(true);
    } finally {
      setLoading(false); // Stop loading after data is fetched
    }
  };


  const handleClear = () => {
    setInputText('');
    setAnalysisData(null);
    setCPTData(null);
    setFetchError(false);
  };


  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div />
        <UserIcon user={user} subscriptionStatus={subscriptionStatus} />
      </div>

      {subscriptionStatus === 'inactive' && (
        <div style={styles.subscriptionBanner}>
          You need to subscribe to use the app.
          <button style={styles.subscribeButton} onClick={handleSubscribe}>Subscribe Now</button>
        </div>
      )}
      {serverError && (
        <div style={styles.errorCard}>
          We're experiencing technical difficulties. Please try again later.
        </div>
      )}
      <div style={styles.inputContainer}>
        <InputTextArea
          onAnalyze={handleAnalyze}
          onClear={handleClear}
          loading={loading}
          disabled={subscriptionStatus === 'inactive' || serverError}
        />
      </div>
      {fetchError && (
        <div style={styles.errorCard}>
          An error occurred while fetching codes. Please try again later.
        </div>
      )}
      {
        analysisData && (
          <>
            <hr style={styles.separator} />
            <div style={styles.section}>
              <TabView activeIndex={activeIndex} onTabChange={handleTabChange} className="centered-tabs">
                <TabPanel header="📋 ICD-10 Codes">
                  <p className="m-0">
                    <DiagnosisCard data={analysisData} />
                    {analysisData.length > 0 ? <CodeExplanation inputText={inputText} codes={analysisData} /> : ''}
                  </p>
                </TabPanel>

                <TabPanel header="⚕️ CPT Codes">
                  <p className="m-0">
                    {(loading && !cptData) ? (
                      <div className="border-round border-1 surface-border p-4 surface-card">
                        <CPTCodeSkeleton />
                      </div>
                    ) : (
                      cptData ? (
                        <CPTCodesDisplay cptData={cptData} />
                      ) : (
                        <div>No CPT data available</div>
                      )
                    )}
                  </p>
                </TabPanel>
              </TabView>
            </div>
            <hr style={styles.separator} />
          </>
        )
      }
    </div>
  );
};

export default Dashboard;