import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useRedirectFunctions, useLogoutFunction } from '@propelauth/react';

const styles = {
  userIconContainer: {
    position: 'relative',
    display: 'inline-block',
    cursor: 'pointer',
    marginRight: '20px',
  },
  userIcon: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    overflow: 'hidden',
    border: '2px solid #007bff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#007bff',
    color: '#fff',
    fontSize: '18px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
  },
  dropdownMenu: {
    position: 'absolute',
    top: '50px',
    right: '0',
    backgroundColor: '#fff',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    overflow: 'hidden',
    zIndex: 1000,
    minWidth: '150px',
    border: '1px solid #e0e0e0',
  },
  dropdownItem: {
    padding: '10px 20px',
    fontSize: '16px',
    color: '#333',
    textDecoration: 'none',
    display: 'block',
    backgroundColor: 'transparent',
    border: 'none',
    textAlign: 'left',
    width: '100%',
    cursor: 'pointer',
  },
  dropdownItemHover: {
    backgroundColor: '#f8f9fa',
  },
};

let endPoint = "https://pairigo-backend.up.railway.app";

if (window.location.href.includes("localhost")) {
  endPoint = "http://127.0.0.1:4242";
}

const UserIcon = ({ user, subscriptionStatus }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const dropdownRef = useRef(null);
  const { redirectToAccountPage } = useRedirectFunctions();
  const logoutFunction = useLogoutFunction();

  const fetchCustomerId = useCallback(async () => {
    try {
      const response = await fetch(`${endPoint}/api/check_subscription`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: user.email }),
      });
      const data = await response.json();
      if (data.has_subscription && data.customer_id) {
        setCustomerId(data.customer_id);
      } else {
        console.log('User is not a paying customer or customer ID is missing');
      }
    } catch (error) {
      console.error('Error fetching customer ID:', error);
    }
  }, [user.email]);

  useEffect(() => {
    if (subscriptionStatus === 'active' && !customerId) {
      fetchCustomerId();
    }
  }, [subscriptionStatus, customerId, fetchCustomerId]);

  const handleToggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const handleSettingsClick = (e) => {
    e.preventDefault();
    redirectToAccountPage();
  };

  const handleLogoutClick = (e) => {
    e.preventDefault();
    logoutFunction();
  };

  const handleMyPlanClick = async (e) => {
    e.preventDefault();
    setDropdownOpen(false);  // Close the dropdown immediately

    try {
      if (!customerId) {
        await fetchCustomerId();
      }

      if (!customerId) {
        throw new Error('No active subscription found for this user');
      }

      const portalResponse = await fetch(`${endPoint}/api/create-customer-portal-session`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ customer_id: customerId }),
      });

      const portalData = await portalResponse.json();
      if (!portalResponse.ok) {
        throw new Error(portalData.error || 'Failed to create portal session');
      }

      if (!portalData.url) {
        throw new Error('No URL returned from the server');
      }

      window.location.href = portalData.url;
    } catch (error) {
      console.error('Error accessing plan details:', error);
      alert(`Unable to access your plan details. Please try again later.`);
    }
  };

  const userInitials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;

  return (
    <div style={styles.userIconContainer} ref={dropdownRef}>
      <div style={styles.userIcon} onClick={handleToggleDropdown}>
        {userInitials}
      </div>
      {isDropdownOpen && (
        <div style={styles.dropdownMenu}>
          <button
            style={styles.dropdownItem}
            onClick={handleSettingsClick}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.dropdownItemHover.backgroundColor}
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
          >
            Settings
          </button>
          {subscriptionStatus === 'active' && (
            <button
              style={styles.dropdownItem}
              onClick={handleMyPlanClick}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.dropdownItemHover.backgroundColor}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
            >
              My Plan
            </button>
          )}
          <button
            style={styles.dropdownItem}
            onClick={handleLogoutClick}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = styles.dropdownItemHover.backgroundColor}
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
          >
            Sign Out
          </button>
        </div>
      )}
    </div>
  );
};

export default UserIcon;