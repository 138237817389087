import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { withAuthInfo } from '@propelauth/react';
import LandingPage from "./LandingPage/LandingPage";
import Dashboard from "./components/Dashboard";
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-indigo/theme.css";  // theme
import "primereact/resources/primereact.min.css";                  // core css
import "primeicons/primeicons.css";                                // icons

let endPoint = "https://pairigo-backend.up.railway.app";

if (window.location.href.includes("localhost")) {
  endPoint = "http://127.0.0.1:4242";
}

const AppContent = withAuthInfo(({ isLoggedIn, user }) => {
  const [subscriptionStatus, setSubscriptionStatus] = useState('loading');

  useEffect(() => {
    const checkUserSubscription = async () => {
      if (isLoggedIn && user?.email) {
        try {
          const response = await fetch(`${endPoint}/api/check_subscription`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: user.email })
          });

          if (!response.ok) throw new Error('Subscription check failed');
          const data = await response.json();
          setSubscriptionStatus(data.has_subscription ? 'active' : 'inactive');
        } catch (error) {
          console.error("Error checking subscription:", error);
          setSubscriptionStatus('error');
        }
      } else {
        setSubscriptionStatus('inactive');
      }
    };

    checkUserSubscription();
  }, [isLoggedIn, user]);

  if (!isLoggedIn) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route
          path="/dashboard"
          element={<Dashboard user={user} subscriptionStatus={subscriptionStatus} />}
        />
      </Routes>
    </Router>
  );
});

const App = () => {
  return (
    <PrimeReactProvider>
      <AppContent />
    </PrimeReactProvider>
  );
};

export default App;