import React, { useState, useCallback } from 'react';

const styles = {
  container: {
    maxWidth: '1200px',
    margin: '20px auto',
    fontFamily: "'Google Sans', sans-serif",
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  header: {
    backgroundColor: '#f8f9fa',
    padding: '15px 20px',
    borderBottom: '1px solid #e9ecef',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: 0,
    color: '#343a40',
  },
  content: {
    padding: '20px',
  },
  codeItem: {
    marginBottom: '15px',
    padding: '10px',
    backgroundColor: '#f8f9fa',
    borderRadius: '4px',
  },
  code: {
    fontWeight: 'bold',
    marginRight: '10px',
    color: '#007bff',
  },
  loading: {
    textAlign: 'center',
    padding: '20px',
    color: '#6c757d',
  },
  loadingSpinner: {
    border: '3px solid #f3f3f3',
    borderTop: '3px solid #007bff',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    animation: 'spin 1s linear infinite',
    display: 'inline-block',
    marginRight: '10px',
    verticalAlign: 'middle',
  },
  error: {
    color: '#dc3545',
    marginTop: '10px',
  },
};

let endPoint = "https://pairigo-backend.up.railway.app";

if (window.location.href.includes("localhost")) {
  endPoint = "http://127.0.0.1:4242";
}

const GenerateHCPCSCodes = ({ inputText }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [HCPCSCodes, setHCPCSCodes] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchHCPCSCodes = useCallback(async () => {
    if (isLoading || HCPCSCodes) return;

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${endPoint}/api/fetch_hcpcs_codes_and_explanations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ chief_complaint: inputText }),
      });
      if (!response.ok) {
        throw new Error('No HCPCS codes found');
      }
      const data = await response.json();
      setHCPCSCodes(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [inputText, isLoading, HCPCSCodes]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    if (!isOpen && !HCPCSCodes && !isLoading) {
      fetchHCPCSCodes();
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.header} onClick={toggleOpen}>
        <h2 style={styles.title}>HCPCS Codes:</h2>
        <span>{isOpen ? '▲' : '▼'}</span>
      </div>
      {isOpen && (
        <div style={styles.content}>
          {isLoading ? (
            <div style={styles.loading}>
              <div style={styles.loadingSpinner}></div>
              Loading HCPCS codes...
            </div>
          ) : error ? (
            <div style={styles.error}>{error}</div>
          ) : HCPCSCodes ? (
            Object.entries(HCPCSCodes).map(([code, description]) => (
              <div key={code} style={styles.codeItem}>
                <span style={styles.code}>{code}</span>
                {description}
              </div>
            ))
          ) : (
            <div>No HCPCS codes available.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default GenerateHCPCSCodes;