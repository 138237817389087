import React from 'react';
import { Card } from 'primereact/card';
import { Skeleton } from 'primereact/skeleton';
import './CPTCodes.css';

const CPTCodesSkeleton = () => {
    return (
        <div className="container">
            {/* Skeleton for the main title */}
            <h2 className="icd-10-title">
                <Skeleton width="20%" height="2rem" />
            </h2>
            {/* Simulate multiple cards */}
            {[...Array(1)].map((_, idx) => (
                <Card key={idx} className="card" title={<Skeleton width="50%" height="1.5rem" />}>
                    <ul className="list">
                        {/* Simulate list items within each card */}
                        {[...Array(3)].map((_, index) => (
                            <li key={index} className="list-item">
                                <div className="flex align-items-center">
                                    <Skeleton width="10%" height="1.2rem" className="mr-2 pb-1" />
                                    <Skeleton width="80%" height="1.2rem" />
                                </div>
                            </li>
                        ))}
                    </ul>
                </Card>
            ))}
        </div>
    );
};

export default CPTCodesSkeleton;
