import React, { useState } from 'react';
import { Card } from 'primereact/card'; // Import PrimeReact Card
import './DiagnosisCard.css'; // Ensure you keep your CSS styling
import CodeInfoCard from './CodeInfoCard';
import * as changeCase from "change-case";

const DiagnosisCard = ({ data }) => {
  const [hoveredCode, setHoveredCode] = useState(null);

  if (!data || !Array.isArray(data)) {
    console.error('Invalid data prop:', data);
    return <div className="error-container">Error: Invalid data format</div>;
  }

  try {
    const groupedData = data.reduce((acc, item) => {
      if (typeof item !== 'object' || !item.Diagnosis) {
        console.warn('Invalid item in data:', item);
        return acc;
      }
      if (!acc[item.Diagnosis]) {
        acc[item.Diagnosis] = [];
      }
      acc[item.Diagnosis].push(item);
      return acc;
    }, {});

    if (Object.keys(groupedData).length === 0) {
      return <div className="error-container">No valid diagnosis data found</div>;
    }

    return (
      <div className="container">
        <h2 className="icd-10-title">ICD-10 Codes</h2>
        {Object.entries(groupedData).map(([diagnosis, items], index) => (
          <Card key={diagnosis} className="card" title={<h2 className="title">{changeCase.sentenceCase(diagnosis)}</h2>}>
            <ul className="list">
              {items.map((item, index) => (
                <li key={index} className="list-item">
                  <div className="description">
                    <div
                      className="code-wrapper"
                      onMouseEnter={() => setHoveredCode(item.Code)}
                      onMouseLeave={() => setHoveredCode(null)}
                    >
                      <span className="code">{item.Code}</span>
                      {hoveredCode === item.Code && (
                        <div className="code-info-container">
                          <CodeInfoCard info={item} />
                        </div>
                      )}
                    </div>
                    {item.Description}
                  </div>
                </li>
              ))}
            </ul>
          </Card>
        ))}
      </div>
    );
  } catch (error) {
    console.error('Error in DiagnosisCard:', error);
    return <div className="error-container">An error occurred while rendering the diagnosis cards: {error.message}</div>;
  }
};

export default DiagnosisCard;
